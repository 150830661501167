<template>
    <article v-editable="blok" :data-page="state?.page?.slug">
        <StoryblokComponent v-for="blok in blok.body" :key="blok._uid" :blok="blok" />
    </article>
</template>

<script setup>
defineProps({ blok: Object })

const state = usePageStore();
</script>